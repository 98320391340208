<template>
    <div class="flex column center" style="margin-bottom:4rem;">
        <div class="new_box">
            <div class="titlee">厦门首起非法买卖中国鲎案件，开审！</div>
            <p class="time">2021-12-15</p>
            <p class="video_center" >
                买卖国家二级保护野生动物<br/>竟只是为了放生？
            </p>
            <div class="col8" style="margin:0rem auto">
                <img class="col3" v-lazy="require('../../assets/case/5-2.png')">
            </div>
            <p class="video_center">
               日前<br/>同安法院审理了一起<br/>危害珍贵、濒危野生动物案
<br/>该案涉嫌非法买卖116只中国鲎[hòu]
<br/>价值16.5万元

            </p>
            <div class="col8" style="margin:1rem auto">
                <img class="col5" v-lazy="require('../../assets/case/5-1.png')">
            </div>
            <div class="video_center" >
                2017年，市民在筼筜湖靠近邮电大厦岸边，看见两只中华鲎趴在浅水区。
            </div>
            <div class="video_center" style="margin:4rem 0">
                这也是今年2月<br/>中国鲎成为国家二级保护野生动物后<br/>我市第一起非法买卖中国鲎的案件

            </div>
            <div class="titlee2">中国鲎-“生物活化石”</div>
            <div class="space_content">
                中国鲎是一种古老的海洋物种，有“生物活化石”之称。独有的蓝色血液具有很高的科研及医用价值。近年的鲎资源调查结果显示：过度的捕食、栖息地遭破坏以及海洋污染是造成中国鲎数量急剧减少的主要原因。三四十年前能在中国东部和南部沿海随处可见的中国鲎，现在已从大部分海域消失。由于幼鲎发育生长到成年鲎需要10年以上的时间，成年鲎被大量捕杀后，中国鲎资源已面临枯竭。
            </div>
            <div class="space_content">
                今年2月，新的《国家重点保护野生动物名录》公布，鲎科的中国鲎和圆尾蝎鲎均成为二级保护动物。
            </div>





            <div class="col8" style="margin:1rem auto">
                <img class="col5" v-lazy="require('../../assets/case/5-3.png')">
            </div>
            <div class="video_center">中国鲎幼苗，厦门日报记者 王火炎 摄</div>
            <div class="video_center">
                令人没想到的是 在中国鲎成为国家二级保护野生动物后 还有人继续非法买卖
            </div>
            <div class="video_center">
            2021年3月至4月期间<br/>被告人王某英多次通过货运公司<br/>将约200斤中国鲎<br/>从广西壮族自治区北海市运至厦门市<br/>以每斤人民币10元至12元不等的价格<br/>出售给被告人王某琛
            </div>
            <div class="video_center">
            今年4月<br/>被告人王某琛向被告人王某英<br/>收购了116只疑似中国鲎的动物<br/>后以每斤11元的价格出售给他人<br/></div>
            <div class="video_center">
            2021年4月2日<br/>中国渔政厦门支队执法人员<br/>在第一码头斜坡及“闽厦渡XXX”船上<br/>查获上述116只疑似中国鲎动物
            </div>
            <div class="video_center">
            经有关部门委托<br/>福建历思司法鉴定所对该生物的物种进行鉴定<br/>这些疑似中国鲎的动物<br/>均为国家二级保护野生动物中国鲎<br/>价值16.5万元
            </div>
            <div class="video_center">
            被告人王某琛辩称<br/>他当时出售这些中国鲎是用来放生的，每斤只卖11元。
            </div>
            <div class="video_center">
            被告人王某英也称<br/>
            自己之所以把手上的中国鲎卖给王某琛，也是出于放生的意图。
            </div>
            <div class="video_center">
            辩护人在法庭上表示<br/>本案被告人认罪态度良好<br/>并且两人已经共同缴纳生态修复金16.5万元<br/>拟专款用于厦门地区<br/>中国鲎的增殖放流及栖息地保护<br/>请求法庭能够从轻处罚<br/>目前<br/>本案仍在进一步审理中

            </div>

            <div class="col8" style="margin:0 auto">
                <img class="col8" v-lazy="require('../../assets/case/5-4.png')">
            </div>
            <div class="video_center">
                任何破坏野生动物资源<br/>破坏环境<br/>侵犯社会公益的行为<br/>都将受到法律制裁和责任追究！<br/>
            </div>
            
        </div>
        <div class="flex wrap col442" style="width:100%;text-align:left;margin-top:1rem">
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex" style="margin-right:10%">
                    <p style="white-space:nowrap">上一篇：</p>无
                    
                </div>
            </div>
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex">
                    <p style="white-space:nowrap">下一篇：</p>
                    <router-link class="a" :to="'/case/env_case/2'"><p class="ellipsis1">全国首例！历思助力技术调查官参审污染环境案件，宣判啦~</p></router-link>
                </div>
            </div>
            <div class="col2 flex  end1 center">
                <router-link class="a" style="color:#fff" :to="'/case/env_case'"><el-button type="primary">返回列表</el-button></router-link >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return { 
            data:{

            }
        }
    },
}
</script>
<style scoped>
.space_content{
    margin-bottom:2rem;
    
}
.video_center{
    letter-spacing:2px;
    text-align:center;
    margin:2rem auto;
    max-width:800px;
}
</style>